import React, { useState } from 'react';
import FreeCoin from '../../img/coin_free.png';
import CashCoin from '../../img/coin_point.png';
import { useSelector } from 'react-redux';
import { GoodsType, GoodsTypeServer } from '../../define';
import { SetBalance } from '../../horsestall/progresscombine/jsUtil_combine';
import { useLang } from '../../lib/hook';

type Props = {
  goodsType: number;
  size: number;
};

type PropsNum = {
  size: number;
};

export function Goods({ goodsType, size }: Props) {
  const { lngT } = useLang('etcs');
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const goodsInfos = [
    { tip: lngT('goods:tooltip:bonus'), img: FreeCoin },
    { tip: lngT('goods:tooltip:cash'), img: CashCoin },
  ];

  if (goodsType === undefined) return <div></div>;

  let goodsInfo = goodsInfos[goodsType];
  const classNameTip = `absolute z-10 top-1/2 left-10 -translate-y-1/2 px-2 py-1 bg-white rounded-md whitespace-nowrap text-xs text-gray-800`;
  return (
    <div
      className="relative"
      onMouseEnter={() => goodsInfo.tip.length > 0 && setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {goodsInfo && <img src={goodsInfo?.img} className={`w-${size} h-${size}`} alt="goods" />}

      {isTooltipVisible && <div className={classNameTip}>{goodsInfo.tip}</div>}
    </div>
  );
}

export function SetCoinType(dispatch: any, CallSetCoinType: any) {
  const CallDispatch = (balance: any) => {
    const minBetbalance = 50;
    const goodsType =
      balance.coin >= minBetbalance || (balance.coin < minBetbalance && balance.free < minBetbalance)
        ? GoodsType.CASHCOIN
        : GoodsType.FREECOIN;
    DispatchCoinType(dispatch, goodsType);
    CallSetCoinType(goodsType);
  };

  SetBalance(CallDispatch);
}

// export function ToggleCoinType(dispatch: any, goodsType: number) {
export function ToggleCoinType(dispatch: any, curGoodsType: number) {
  const togglegoodsType = curGoodsType === GoodsType.CASHCOIN ? GoodsType.FREECOIN : GoodsType.CASHCOIN;
  DispatchCoinType(dispatch, togglegoodsType);
  return togglegoodsType;
}

export function DispatchCoinType(dispatch: any, coinType: number) {
  dispatch({
    type: 'GOODSTYPE',
    goodsType: coinType,
  });
}

export function GetCoinType() {
  const goodsType = useSelector((state: any) => state.goodsType);
  // console.log('GetCoinType : ' + goodsType);
  return goodsType;
}

export function USDPerGoods({ size }: PropsNum) {
  const goodsType = GetCoinType();
  const perGoods = goodsType === GoodsType.CASHCOIN ? 100 : 10000;
  return (
    <div className="flex flex-row text-base">
      {perGoods} <Goods goodsType={goodsType} size={size} />
      =1USD
    </div>
  );
}

export function GetBalance(goodsType: any, balance: any) {
  if (goodsType === 1) {
    return balance?.data?.coin;
  } else {
    return balance?.data?.free;
  }
}

export function GetFixedBalanceStr(goodsType: any, balance: any) {
  if (goodsType === 1) {
    return balance?.data?.coin?.toFixed(1).toLocaleString();
  } else {
    return balance?.data?.free?.toFixed(1).toLocaleString();
  }
}

export function GetCoinTypeToServer() {
  return GoodsTypeServer[GetCoinType()];
}
