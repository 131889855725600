import React, { useState, useEffect, useRef } from 'react';
import { useLang } from '@/lib/hook';

export default function OddEvenTable({ raceList, tabOdd, setTabOdd, ODDEVENTAB, OVERUNDERTAB }) {
  const [newOddEven, setNewOddEven] = useState([]);
  const [newOverUnder, setNewOverUnder] = useState([]);

  //const currentDate = new Date();
  //const currentDateString = currentDate.toISOString().split('T')[0];

  // raceList 배열에서 현재 날짜와 동일한 날짜를 가진 데이터만 필터링
  //const filteredData = raceList?.filter(race => race.TimeRace.startsWith(currentDateString));
  const filteredData = raceList;

  const filteredRank = filteredData.map((data, i) => data.rank);

  useEffect(() => {
    let oddEvenArray = [];
    let overUnderArray = [];
    if (filteredRank !== null) {
      for (let i = 0; i < filteredRank.length; i++) {
        if (filteredRank[i] !== null) {
          const oddEven = filteredRank[i][0] % 2 === 1 ? 'O' : 'E';
          const overUnder = filteredRank[i][0] <= 6 ? 'U' : 'O';
          oddEvenArray.push({ winner: oddEven });
          overUnderArray.push({ winner: overUnder });
        }
      }
      console.log('filteredRank', filteredRank);
    }
    setNewOddEven(oddEvenArray);
    setNewOverUnder(overUnderArray);
  }, [raceList.length]);

  function handleCellHover(item, rowIndex, columnIndex) {
    // 마우스 hover 시 해당 데이터의 index를 표시
    const cellText = `Round: ${rowIndex + columnIndex * 6 + 1}`;

    showCellText(cellText, rowIndex, columnIndex);
    // handleScroll(rowIndex);
  }

  function handleCellMouseOut() {
    // 마우스가 셀을 빠져나갈 때 수행할 동작
    hideCellText();
  }

  function showCellText(text, rowIndex, columnIndex) {
    const cellTextElement = document.createElement('div');
    cellTextElement.className = 'cellText';
    cellTextElement.textContent = text;
    cellTextElement.style.fontSize = '10px';
    cellTextElement.style.width = '50px';
    cellTextElement.style.backgroundColor = '#fff';
    cellTextElement.style.borderRadius = '18px';
    cellTextElement.style.textAlign = 'center';
    cellTextElement.style.position = 'absolute';
    cellTextElement.style.left = `${columnIndex * 50}px`; // Adjust this as needed
    cellTextElement.style.top = `${rowIndex * 48}px`; // Adjust this as needed
    cellTextElement.style.zIndex = '100'; // Ensure it's displayed above the table
    const table = document.getElementById('table');
    table.appendChild(cellTextElement);
  }

  function hideCellText() {
    const cellTextElements = document.querySelectorAll('.cellText');
    cellTextElements.forEach(element => {
      element.remove();
    });
  }

  //스크롤 화면에 맞춰서 나타내고 움직이기

  const containerRef = useRef(null);
  const cellSize = 50; // Set an appropriate size for your cells
  const [visibleRows, setVisibleRows] = useState(18);
  const [scrollWidth, setScrollWidth] = useState(0);

  function initScrollLeft(container) {
    const initialScrollIndex = Math.max(visibleRows * cellSize, 0);
    container.scrollLeft = initialScrollIndex;
  }

  useEffect(() => {
    if (filteredRank.length <= 102) {
      const container = containerRef.current;
      setVisibleRows(18);
    } else {
      setVisibleRows(Math.ceil(filteredRank.length / 6));
    }
    const container = containerRef.current;
    if (!container) return;
    initScrollLeft(container);
  });

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;
    //데이터가 적을경우 맨 앞으로 보여지기
    if (container.scrollWidth === scrollWidth) {
      if (filteredRank.length < 102) {
        container.scrollLeft = 0;
        // setScrollWidth(container.scrollWidth);
      }
    }
    if (container.scrollWidth !== scrollWidth) {
      var newScrollWidth = container.scrollWidth;
      setScrollWidth(newScrollWidth);
      initScrollLeft(container);
      if (filteredRank.length < 102) {
        setScrollWidth(container.scrollWidth);
      }
    }
  });

  console.log(tabOdd, 'taboDd@@@@@@@');
  return (
    <div className="flex flex-col space-y-4 pt-4 h-full w-full max-w-4xl mx-auto">
      <div className="flex flex-row justify-center mx-auto w-full D:2/3 LD:w-1/2 h-[40px] D:h-[48px] rounded-sm space-x-2 D:text-xl text-lg overflow-hidden">
        <button
          style={{
            ...{
              background:
                tabOdd === OVERUNDERTAB
                  ? 'linear-gradient(135deg, #3689d7 0%, #1564af 100%)'
                  : 'linear-gradient(135deg, #dedeee 0%, #BBBBBB 77%)',
            },
            boxShadow: '2px 2px 6px rgba(13, 137, 252, 0.35) inset',
          }}
          className={`flex justify-center items-center flex-1  text-white rounded-lg hover:brightness-100 transition-all ${
            tabOdd === OVERUNDERTAB ? 'bg-[#1564af] brightness-125 ' : 'bg-[#9d9d9d] brightness-75 text-white/60'
          }`}
          onClick={() => setTabOdd(OVERUNDERTAB)}
        >
          UNDER/OVER
          {/* History `${lngT('information:history')}` */}
        </button>
        <button
          style={{
            ...{
              background:
                tabOdd === ODDEVENTAB
                  ? 'linear-gradient(135deg, #3689d7 0%, #1564af 100%)'
                  : 'linear-gradient(135deg, #dedeee 0%, #BBBBBB 77%)',
            },
            boxShadow: '2px 2px 6px rgba(13, 137, 252, 0.35) inset',
          }}
          className={`flex justify-center items-center flex-1 text-white rounded-lg hover:brightness-90 transition-all ${
            tabOdd === ODDEVENTAB ? 'bg-[#1564af] brightness-125 ' : 'bg-[#9d9d9d] brightness-75 text-white/60'
          }`}
          onClick={() => setTabOdd(ODDEVENTAB)}
        >
          ODD/EVEN
          {/*Horse List lngT('list:title')*/}
        </button>
      </div>
      {tabOdd === OVERUNDERTAB ? (
        <>
          <div
            id="table"
            ref={containerRef}
            class="relative overflow-x-auto shadow-md sm:rounded-lg rounded-md"
            style={{ overflowX: 'auto' }}
          >
            <table class="w-full text-sm text-left bg-white border-collapse border-[1px] border-gray-300 h-full">
              <tbody>
                {Array.from({ length: 6 }, (_, rowIndex) => (
                  <tr key={rowIndex} class="overflow-x-auto flex flex-row w-full">
                    {Array.from({ length: visibleRows }, (_, columnIndex) => (
                      <th
                        key={columnIndex}
                        scope="col"
                        class="flex flex-col font-medium w-fit px-0 py-0 border border-gray-300"
                        onMouseOver={() =>
                          handleCellHover(newOverUnder[rowIndex + columnIndex * 6], rowIndex, columnIndex)
                        }
                        onMouseOut={handleCellMouseOut}
                      >
                        <div className="px-2 py-2">
                          {newOverUnder[rowIndex + columnIndex * 6] ? (
                            <div
                              class={`w-8 h-8 text-white ${
                                newOverUnder[rowIndex + columnIndex * 6].winner === 'O' ? 'bg-blue-500' : 'bg-red-500'
                              } rounded-full flex justify-center items-center`}
                            >
                              {newOverUnder[rowIndex + columnIndex * 6].winner}
                            </div>
                          ) : (
                            <div class={`w-8 h-8 bg-transparent rounded-full flex justify-center items-center`} />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <>
          <div
            id="table"
            ref={containerRef}
            class="relative overflow-x-auto shadow-md sm:rounded-lg"
            style={{ overflowX: 'auto' }}
          >
            <table class="w-full text-sm text-left bg-white border-collapse border-[1px] border-gray-300 h-full">
              <tbody>
                {Array.from({ length: 6 }, (_, rowIndex) => (
                  <tr key={rowIndex} class="overflow-x-auto flex flex-row w-full">
                    {Array.from({ length: visibleRows }, (_, columnIndex) => (
                      <th
                        key={columnIndex}
                        scope="col"
                        class="flex flex-col font-medium w-fit px-0 py-0 border border-gray-300"
                        onMouseOver={() =>
                          handleCellHover(newOddEven[rowIndex + columnIndex * 6], rowIndex, columnIndex)
                        }
                        onMouseOut={handleCellMouseOut}
                      >
                        <div className="px-2 py-2">
                          {newOddEven[rowIndex + columnIndex * 6] ? (
                            <div
                              class={`w-8 h-8 text-white ${
                                newOddEven[rowIndex + columnIndex * 6].winner === 'E' ? 'bg-blue-500' : 'bg-red-500'
                              } rounded-full flex justify-center items-center`}
                            >
                              {newOddEven[rowIndex + columnIndex * 6].winner}
                            </div>
                          ) : (
                            <div class={`w-8 h-8 bg-transparent rounded-full flex justify-center items-center`} />
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}
